//Switch pages from telescope to inside the telescope
showInsideTelescope();
let insideTelescope = document.querySelector(".telescope-inside");
let telescope = document.querySelector(".telescope-original");
let backBtn = document.querySelector(".telescope__back-btn");

function hideIntroSection() {
    const introSection = document.querySelector("section.intro");
    if (!introSection) return;
    introSection.classList.add("hidden");
}

function showIntroSection() {
    const introSection = document.querySelector("section.intro");
    if (!introSection) return;
    introSection.classList.remove("hidden");
}

function showInsideTelescope() {
    const trigger1 = document.querySelector(".telescope__dot-box");
    const trigger2 = document.querySelector(".telescope__description");

    function triggerContent() {
        hideIntroSection();
        insideTelescope.classList.remove("hidden");
        telescope.classList.toggle("hidden");
    }

    trigger1.addEventListener("click", () => {
        triggerContent();
    });
    trigger2.addEventListener("click", () => {
        triggerContent();
    });
}

//BackBtn
backBtnFunction();

function backBtnFunction() {
    backBtn.addEventListener("click", () => {
        showIntroSection();
        insideTelescope.classList.add("hidden");
        telescope.classList.remove("hidden");
        document
            .querySelector(
                ".telescope__responsive-container.sentinel-5 .telescope__dot-box"
            )
            .focus();
    });
}

//Popups
popupVN();
popupUS();
popupUV1();

function popupVN() {
    const popupTriggerDotVN = document.querySelector(
        ".tsboa-vn .telescope__dot-box"
    );
    const popupTriggerTitleVN = document.querySelector(
        ".tsboa-vn .telescope__description"
    );
    const popupVN = document.querySelector(".popup.tsboa-vn");
    const popupBackBtn = document.querySelector(".tsboa-vn .popup__back-btn");

    function triggerActivity() {
        popupVN.classList.toggle("show");

        document.addEventListener("mouseup", (event) => {
            const withinBoundaries = event.composedPath().includes(popupVN);
            if (withinBoundaries === false) {
                if (popupVN.classList.contains("show")) {
                    popupVN.classList.remove("show");
                    backBtn.classList.remove("hidden-visibility");
                }
            }
        });

        popupVN.setAttribute("tabindex", 0);
        document.getElementById("tsboa-vn").focus();
        backBtn.classList.add("hidden-visibility");
    }

    popupTriggerDotVN.addEventListener("click", (e) => {
        triggerActivity();
    });

    popupTriggerTitleVN.addEventListener("click", (e) => {
        triggerActivity();
    });

    function btnCloseActivity() {
        popupVN.classList.remove("show");
        popupVN.setAttribute("tabindex", -1);
        document
            .querySelector(
                ".telescope__responsive-container-item.tsboa-us .telescope__dot-box"
            )
            .focus();
        backBtn.classList.remove("hidden-visibility");
    }

    popupBackBtn.addEventListener("click", (e) => {
        btnCloseActivity();
    });

    window.addEventListener("keydown", function (event) {
        const key = event.key; // const {key} = event; in ES6+
        if (key === "Escape") {
            btnCloseActivity();
        }
    });
}

function popupUS() {
    const popupTriggerDotUS = document.querySelector(
        ".tsboa-us .telescope__dot-box"
    );
    const popupTriggerTitleUS = document.querySelector(
        ".tsboa-us .telescope__description"
    );
    const popupUS = document.querySelector(".popup.tsboa-us");
    const popupBackBtn = document.querySelector(".tsboa-us .popup__back-btn");

    function triggerActivity() {
        popupUS.classList.toggle("show");

        document.addEventListener("mouseup", (event) => {
            const withinBoundaries = event.composedPath().includes(popupUS);
            if (withinBoundaries === false) {
                if (popupUS.classList.contains("show")) {
                    popupUS.classList.remove("show");
                    backBtn.classList.remove("hidden-visibility");
                }
            }
        });

        popupUS.setAttribute("tabindex", 0);
        document.getElementById("tsboa-us").focus();
        backBtn.classList.add("hidden-visibility");
    }

    popupTriggerDotUS.addEventListener("click", (e) => {
        triggerActivity();
    });

    popupTriggerTitleUS.addEventListener("click", (e) => {
        triggerActivity();
    });

    function btnCloseActivity() {
        popupUS.classList.remove("show");
        popupUS.setAttribute("tabindex", -1);
        document
            .querySelector(
                ".telescope__responsive-container-item.uv1-so .telescope__dot-box"
            )
            .focus();
        backBtn.classList.remove("hidden-visibility");
    }

    popupBackBtn.addEventListener("click", (e) => {
        btnCloseActivity();
    });
    window.addEventListener("keydown", function (event) {
        const key = event.key; // const {key} = event; in ES6+
        if (key === "Escape") {
            btnCloseActivity();
        }
    });
}

function popupUV1() {
    const popupTriggerDotUV1 = document.querySelector(
        ".uv1-so .telescope__dot-box"
    );
    const popupTriggerTitleUV1 = document.querySelector(
        ".uv1-so .telescope__description"
    );
    const popupUV1 = document.querySelector(".popup.uv1-so");
    const popupBackBtnUV1 = document.querySelector(".uv1-so .popup__back-btn");

    function triggerActivity() {
        popupUV1.classList.toggle("show");

        document.addEventListener("mouseup", (event) => {
            const withinBoundaries = event.composedPath().includes(popupUV1);
            if (withinBoundaries === false) {
                if (popupUV1.classList.contains("show")) {
                    popupUV1.classList.remove("show");
                    backBtn.classList.remove("hidden-visibility");
                }
            }
        });

        popupUV1.setAttribute("tabindex", 0);
        document.getElementById("uv1-so").focus();
        backBtn.classList.add("hidden-visibility");
    }

    popupTriggerDotUV1.addEventListener("click", (e) => {
        triggerActivity();
    });

    popupTriggerTitleUV1.addEventListener("click", (e) => {
        triggerActivity();
    });

    function btnCloseActivity() {
        popupUV1.classList.remove("show");
        popupUV1.setAttribute("tabindex", -1);
        document.querySelector(".telescope__back-btn").focus();
        backBtn.classList.remove("hidden-visibility");
    }

    popupBackBtnUV1.addEventListener("click", (e) => {
        btnCloseActivity();
    });

    window.addEventListener("keydown", function (event) {
        const key = event.key; // const {key} = event; in ES6+
        if (key === "Escape") {
            btnCloseActivity();
        }
    });
}

function getScreenSize() {
    const html = document.getElementsByTagName("html")[0];

    if (window.innerWidth > 1024) {
        html.classList.add("is-desktop");
    } else {
        html.classList.remove("is-desktop");
    }
}
addEventListener("resize", (event) => {
    getScreenSize();
});
getScreenSize();
